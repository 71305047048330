<template>
    <div class="follow-mp">
        <img class="logo" src="@/assets/logo.png" alt="">
        <div class="text">
            <div class="title">
                讯罗微视网校系统
            </div>
            <div class="subtitle">
                关注公众号解锁更多功能!
            </div>
        </div>
        <div class="follow-btn" @click="showGh=true"> 关 注 </div>
        <van-dialog class="follow-dialog" v-model="showGh" title="关注" showCancelButton @confirm="followMp">
            <img class="qrcode" src="@/assets/imgs/gh_test.jpg" />
        </van-dialog>
    </div>
</template>

<script>
    
    export default {
        name: 'FollowMp',
        data(){
            return {
                showGh: false,
            }
        },
        
        methods: {
            followMp(){
                let uin_base64 = "MzkxMTYyNjE5Ng==";
                let _url = "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz="+uin_base64+"&scene=124#wechat_redirect";
                window.location.href = _url;
            }
        }
    }
</script>

<style lang="less" scoped>
.follow-mp {
    margin: 15px auto;
    padding: 15px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
        height: 50px;
        padding-right: 15px;
    }
    .text {
        flex: 1;
        text-align: left;
        .title {
            font-weight: bold;
        }
        .subtitle {
            font-size: 14px;
            color: #999;
        }
    }
    .follow-btn {
        padding: 5px 15px;
        border-radius: 15px;
        font-weight: bold;
        background: linear-gradient(to right, #facb22, #f86f00);
        color: #fff;
    }
    .follow-dialog {
        text-align: center;
        .qrcode {
            width: 80%;
        }
    }
    
} 
</style>