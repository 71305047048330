<template>
    <div class="footer">
      <img class="logo" src="@/assets/imgs/logo_square.png" alt="">
    </div>
</template>
<script>

export default {
  name: 'Footer',
  data(){
    return {

    }
  },
  methods:{
    
  }
}
</script>
<style lang="less" scoped>
.footer{
  padding-bottom: 50px;
  color: #999;
  display: flex;
  justify-content: center;
  align-items: center;
  .logo {
    height: 50px;
    opacity: .3;
  }
  
}
</style>
